.header-txt {
  display: flex;
  justify-content: center;
}

.header-body {
  height: 8%;
  width: 100%;
  position: fixed;
  top: 0px;
  background-color: rgb(240, 240, 240);
  color: #333;
  border-bottom: 1px #AAA solid;
  box-shadow: 0px 1px 5px rgba(240, 240, 240);
  z-index: 100;
}



.submit-btn {
  display: flex;
  justify-content: end;
  top: 10ox;
  margin-top: 20px;
  margin-right: 20px;
  gap: 10px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.text-area-form{
  display: flex;
    text-align: center;
    justify-content: center;
}


@media (max-width: 768px) {

  .header-form {
    width: 100%;
  }
  
}

/* Hide sidebar toggle on larger screens */
@media (min-width: 769px) {
  .header-form{
    width: 50%;
  }
  
}
.no-border-cell {
  border-bottom: none !important;
}